import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
  BarChart,
  Bar,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import moment from "moment";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import WaterBalanceResultMenuMessages from "components/WaterBalanceResultMenu/WaterBalanceResultMenuMessages";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAnimalWelfareTable, getGrasslandGraph, getGrasslandTable } from "store/analisys/api";
import { ROUTES } from "constants/routes";
import { printVarsHook } from "utils/printVarsHook";
import CustomTick from "components/CustomTick/CustomTick";

class AnimalWelfareContentProps {
  result: any;
}

function getEquidistantDates(crop: any, n: number) {
  const startDateList = crop.periods.map(
    (period: any) => new Date(period.start_date)
  );
  const endDateList = crop.periods.map(
    (period: any) => new Date(period.end_date)
  );
  const startDate = new Date(Math.min(...startDateList));
  const endDate = new Date(Math.max(...endDateList));
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDiff = end.getTime() - start.getTime();
  const interval = totalDiff / (n + 1);
  const dates = [];
  for (let i = 1; i <= n; i++) {
    const date = new Date(start.getTime() + interval * i);
    dates.push(moment(date).format("YYYY-MM-DD"));
  }

  return dates;
}

const AnimalWelfareContent = ({ result }: AnimalWelfareContentProps) => {
  const intl = useIntl();
  const [inactiveKeysPeriod, setInactiveKeyPeriod] = useState(Array<string>());
  const [graphValues, setgraphValues] = useState(Array());
  const [tableValues, setTableValues] = useState<any[]>([]);
  const [getError, setGetError] = useState(null);
  const {
    client_id,
    project_id,
    water_balance_id,
    id,
    simulation_water_balance_id,
  } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // getGrasslandGraph({
    //   clientId: client_id,
    //   projectId: project_id,
    //   simulationId: water_balance_id,
    //   id: id || simulation_water_balance_id,
    // })
    //   .then((res: any) => setgraphValues(res.data))
    //   .catch((err) => {
    //     setGetError(err.response.data.data);
    //   });
    getAnimalWelfareTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    })
      .then((res: any) => setTableValues(res.data))
      .catch((err) => {
        setGetError(err.response.data.data);
      });
  }, [client_id, project_id, water_balance_id, id, result]);

  printVarsHook(tableValues)

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  const GrasslandTypeOptions = [
    { label: "Harvestable production", value: "HARVESTABLE" },
    { label: "Growth dynamic", value: "DYNAMIC" },
  ];

  const HarvestableUnite = [
    {
      label: "%",
      value: "%",
    },
    {
      label: "tMS/ha",
      value: "tMS/ha",
    },
  ];

  const [selectedGrassTypeOptions, setSelectedGrassTypeOptions] = useState<
    "HARVESTABLE" | "DYNAMIC"
  >("HARVESTABLE");

  const [selectedGrasslandUnite, setSelectedGrasslandUnite] = useState<
    "%" | "tMS/ha"
  >("%");

  const relaunchSimulation = () => {
    navigate(
      ROUTES.WATER_BALANCES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", water_balance_id as string)
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    const chart_type = searchParams.get('chart_type')
    const unit = searchParams.get('unit')
    if (GrasslandTypeOptions.find((item) => item.value == chart_type))
      setSelectedGrassTypeOptions(chart_type as any)
    else
      newParams.set("chart_type", selectedGrassTypeOptions)

    if (HarvestableUnite.find((item) => item.value == unit))
      setSelectedGrasslandUnite(unit as any)
    else
      newParams.set("unit", selectedGrasslandUnite)

    setSearchParams(newParams)
  }, [])

  if (!tableValues || !tableValues.length)
    return getError === null ? (
      <div style={{ height: "100vh" }}></div>
    ) : (
      <div className={styled.errorToGet}>
        <p onClick={relaunchSimulation}>{getError}</p>
      </div>
    );

  type DbData = {
    seuil: string;
    futur_control: number;
    actuel_control: number;
    futur_agripv: number;
    actuel_agripv: number;
  };

  type TransformedData = {
    zone: string;
    [key: string]: number | string;
  };

  const transformData = (dbData: DbData[]): TransformedData[] => {
    const zones = ["actuel_control", "actuel_agripv"];

    const transformedData: TransformedData[] = zones.map((zone) => ({ zone }));

    dbData.forEach((entry) => {
      zones.forEach((zone) => {
        const zoneData = transformedData.find((data) => data.zone === zone);
        if (zoneData) {
          zoneData[entry.seuil] = entry[zone as keyof DbData];
        }
      });
    });

    transformedData.forEach(item => item.zone == "actuel_control" ? item.zone = "Control" : item.zone = "Under Panels")

    return transformedData;
  };

  const colors = {
    "[68;72[": "#8884d8",
    "[72;78[": "#82ca9d",
    "[78;82[": "#ffc658",
    "[82;84[": "#ff8042",
    "[84;86]": "#a4de6c",
    "> 86": "#d0ed57",
  };

  return (
    <div className={styled.list}>
      {tableValues && tableValues.length > 0 && (
        <div className={styled.wrapper} style={{ display: "flex" }}>
          <div className={styled.chartContainer}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className={styled.optionContainer}>
                <div className={styled.chartTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.tableTitleAnimalWelfare} />
                  <div className={styled.subTitle}>
                    <FormattedMessage {...WaterBalanceResultMenuMessages.tableTitleAnimalWelfareSubTitle} />
                  </div>
                </div>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("FrostStressChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
              </div>
              <div id={"FrostStressChart"}>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={transformData(tableValues)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="zone" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {Object.keys(colors).map((key) => (
                      <Bar key={key} dataKey={key} stackId="a" fill={(colors as any)[key]} />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}

      {tableValues && (
        <div className={styled.tableContainer}>
          <div className={styled.downloadContainer}>
            <div className={styled.chartTitle}>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.tableTitleAnimalWelfareSubTitle}
              />
            </div>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng("GrasslandYieldTable")}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  tableValues,
                  `${result.simulation.name} table data`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
          <div className={`${styled.flex} ${styled.tableContainerOverflow}`}>
            <table className={styled.dataTable} id="GrasslandYieldTable">
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </th>
                  {/* <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {[...tableValues.filter(item => item.seuil != "Sum"), tableValues.find(item => item.seuil == "Sum")].map(item => <tr>
                  <td>{item.seuil}</td>
                  <td>{item.actuel_control}</td>
                  <td>{item.actuel_agripv}</td>
                  {/* <td>{item.futur_control}</td> */}
                  {/* <td>{item.futur_agripv}</td> */}
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      )
      }
    </div >
  );
};

export default AnimalWelfareContent;
