import { useOptionData } from "hooks/useOptionData";
import { ICrops } from "pages/Crops/Crops";
import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import CropTemplateModalMessages from "./CropTemplateModalMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import { ICropType } from "pages/CropsCreate/CropsCreateTypes";
import { Tooltip } from "@mui/material";

type ICropTemplateModal = {
  isOpen: boolean;
  templates: ICrops[];
  type: ICropType;
  onHideModal: () => void;
  onSelectCropTemplate: (template: ICrops) => void;
  onBack: () => void;
  onToggleType: () => void;
};

const CropTemplateModal = ({
  isOpen,
  templates,
  type,
  onToggleType,
  onHideModal,
  onSelectCropTemplate,
  onBack,
}: ICropTemplateModal) => {
  const [templateSelected, setTemplateSelected] = useState<ICrops | null>(null);
  const [yearToEdit, setYearToEdit] = useState(2020);

  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const displayDate = (date: string | undefined) => {
    if (!date)
      return ''
    const month = monthList[Number(date.substring(5).split('-')[0]) - 1]
    return `${date.substring(5).split('-')[1]}  ${month}`
  }

  const intl = useIntl();

  const toggle = () => {
    setTemplateSelected(null);
    onToggleType();
  };

  useEffect(() => {
    if (!isOpen) setTemplateSelected(null);
  }, [isOpen]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key == "Escape") {
        onHideModal();
      }
    };
    if (isOpen) window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, [isOpen]);

  useEffect(() => {
    if (templateSelected) {
      if (type == ICropType.normalDates) {
        setTemplateSelected((prev) => {
          return {
            ...prev!,
            periods: prev!.periods.map((period) => {
              let start = period.start_date!.split("-");
              let end = period.end_date!.split("-");
              end[0] = start[0] = String(yearToEdit);
              return {
                ...period,
                start_date: start.join("-"),
                end_date: end.join("-"),
              };
            }),
          };
        });
      } else {
        setTemplateSelected((prev) => {
          return {
            ...prev!,
            periods: prev!.periods.map((period) => {
              let sowing_date = period.sowing_date!.split("-");
              sowing_date[0] = String(yearToEdit);
              return {
                ...period,
                sowing_date: sowing_date.join("-"),
              };
            }),
          };
        });
      }
    }
  }, [yearToEdit]);

  if (!isOpen) return <></>;
  return (
    <div className={styled.absolute}>
      <div className={styled.modalBackground}>
        <div className={styled.modalContainer}>
          <div className={styled.leftSide}>
            <div className={styled.templateSelectorLabel}>
              <FormattedMessage
                {...CropTemplateModalMessages.selectTemplateType}
              />
            </div>
            <div className={styled.littleLimiter} />
            <div className={styled.templateTypeSelector}>
              <div
                className={clsx(styled.templateTypeSelectorItem, {
                  [styled.selected]: type == ICropType.normalDates,
                })}
                onClick={toggle}
              >
                Dates
              </div>
              <div
                className={clsx(styled.templateTypeSelectorItem, {
                  [styled.selected]: type == ICropType.degreeDays,
                })}
                onClick={toggle}
              >
                Degree Days
              </div>
            </div>

            <div className={styled.templateSelectorLabel}>
              <FormattedMessage {...CropTemplateModalMessages.selectTemplate} />
            </div>
            <div className={styled.littleLimiter} />
            <div className={styled.templateSelector}>
              {templates.map((crop: any) => (
                <div
                  key={crop.name}
                  className={clsx(styled.templateSelectorItem, {
                    [styled.selected]:
                      templateSelected && templateSelected.name == crop.name,
                  })}
                  onClick={() => setTemplateSelected(crop)}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                    <Crops />
                    {crop.name}
                  </div>
                  {!!crop.tooltip && <Tooltip title={crop.tooltip}>
                    <div className={styled["help-icon"]} style={{ width: "20px", marginLeft: "auto" }}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </Tooltip>}

                </div>
              ))}
            </div>
          </div>
          <div className={styled.rightSide}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                className={styled.templateLabel}
                disabled
                value={templateSelected ? templateSelected.name : ""}
                placeholder={intl.formatMessage(
                  CropTemplateModalMessages.selectTemplate
                )}
              />
              <div style={{ display: "flex", gap: "1rem" }}>
                <div className={styled.cancelButton} onClick={onBack}>
                  <FormattedMessage {...CommonMessages.cancel} />{" "}
                </div>

                {/* <input
                  type="number"
                  className={clsx(styled.selectButton, styled.edit, {
                    [styled.disabled]: templateSelected == null,
                  })}
                  disabled={templateSelected == null}
                  placeholder={intl.formatMessage(
                    CropTemplateModalMessages.editYear
                  )}
                  value={yearToEdit}
                  onChange={(e) => setYearToEdit(Number(e.currentTarget.value))}
                  min={0}
                /> */}

                <div
                  className={clsx(styled.selectButton, {
                    [styled.disabled]: templateSelected == null,
                  })}
                  onClick={() => onSelectCropTemplate(templateSelected!)}
                >
                  <FormattedMessage
                    {...CropTemplateModalMessages.selectTemplate}
                  />{" "}
                </div>
              </div>
            </div>

            <div className={styled.littleLimiter} />

            <div className={styled.tableContainer}>
              <table className={styled.table}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.stageName}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.cropHeight}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...(type == ICropType.normalDates
                          ? CropTemplateModalMessages.startDate
                          : CropTemplateModalMessages.startDegreeDay)}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...(type == ICropType.normalDates
                          ? CropTemplateModalMessages.endDate
                          : CropTemplateModalMessages.endDegreeDay)}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.culturalCoefficient}
                      />
                    </th>
                    {type == ICropType.degreeDays && <>
                      <th>
                        <FormattedMessage
                          {...CropTemplateModalMessages.sowingDate}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...CropTemplateModalMessages.baseTemperature}
                        />
                      </th>
                    </>}
                  </tr>
                </thead>
                <tbody>
                  {templateSelected &&
                    templateSelected.periods.map((item) => (
                      <tr key={`template ${item.name}`}>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.name}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_height ? item.crop_height : 0}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {type == ICropType.normalDates
                              ? displayDate(item.start_date)
                              : item.start_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {type == ICropType.normalDates
                              ? displayDate(item.end_date)
                              : item.end_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_coefficient}
                          </div>
                        </td>
                        {type == ICropType.degreeDays && <>
                          <td>
                            {displayDate(item.sowing_date)}
                          </td>
                          <td>
                            {item.base_degree_day}
                          </td>
                        </>}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropTemplateModal;
