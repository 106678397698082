import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "components/common/CommonMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import SimulationDropItemMessages from "components/SimulationDropItem/SimulationDropItemMessages";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";
import CropsRenderList from "components/SimulationDropItem/CropsRenderList";
import SoilInformationsRenderList from "components/WaterBalanceDropItem/SoilInformationsRenderList";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import Line from "components/WaterBalanceDropItem/Line";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import PVModulePropertiesRenderList from "components/SimulationDropItem/PVModulePropertiesRenderList";
import StructuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import GeneralRenderList from "components/SimulationDropItem/GeneralRenderList";
import GrasslandYieldPropertiesRenderList from "components/SimulationDropItem/GrasslandProperties";
import { Tooltip } from "@mui/material";
import StructuresRenderListMessages from "components/SimulationDropItem/StructuresRenderListMessages";
import SoilInformationsCreateMessages from "pages/SoilInformationsCreate/SoilInformationsCreateMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import AddModalPeriodCropsTableMessages from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTableMessages";
import DatasetsMessages from "pages/Datasets/DatasetsMessages";
import DatasetModelMessages from "components/DatasetModel/DatasetModelMessages";
import DatasetsEditMessages from "pages/DatasetsCreate/DatasetsEditMessages";
import { useSearchParams } from "react-router-dom";

const SimulationConfigContent = ({ result }: any) => {

  const intl = useIntl();

  const simu_obj = useMemo(() => {
    return {
      ["General"]: result.simulation,
      ["Structure"]: result.simulation.structure,
      ["Soil Information"]: result.simulation.water_balance,
      ["Crop"]: result.simulation.crop_for_water_balance,
      ["Steering Algorithm"]:
        result.simulation.steering_algorithm_for_water_balance,
      ["Weather Dataset"]: result.simulation.universal_dataset
        ? null
        : result.simulation.weather_dataset,
      ["ETP Dataset"]: result.simulation.universal_dataset
        ? null
        : result.simulation.ETP_dataset,
      ["Climatic Dataset"]: result.simulation.universal_dataset
        ? result.simulation.universal_dataset
        : null,
      ["Pv Module Properties"]: result.simulation.structure,
      ["Grassland Yield Properties"]: result.simulation.grassland_primary_yield
        ? result.simulation.grassland_primary_yield
        : null,
    };
  }, [result]);

  const [selectedObject, setSelectedObject] = useState<keyof typeof simu_obj>(
    Object.keys(simu_obj)[0] as keyof typeof simu_obj
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    const menu = searchParams.get('menu')
    const section = searchParams.get('section')
    if (menu && menu == "Simulation Parameters") {

      if (!Object.keys(simu_obj).find(value => value == section)) {
        newParams.set("section", "General")
        setSearchParams(newParams)
      } else
        setSelectedObject(section as any)
    }

  }, [simu_obj])

  const selectObject = (key: any) => {
    setSelectedObject(key)

    const newParams = new URLSearchParams(searchParams);
    newParams.set("section", key)
    setSearchParams(newParams)
  }


  const InputDisplay = () => {
    if (selectedObject == "Structure")
      return (
        <>
          <div className={styled.title}>
            <Structures />
            {simu_obj.Structure.name}
          </div>
          <StructuresRenderList selected={simu_obj.Structure} />
        </>
      );
    if (selectedObject == "Soil Information")
      return (
        <>
          <div className={styled.title}>
            <Icon
              name={ICON_NAMES_ENUM.terrain_icon}
              className={styled.file__icon}
            />
            {simu_obj["Soil Information"].name}
          </div>
          <SoilInformationsRenderList selected={simu_obj["Soil Information"]} />
        </>
      );
    if (selectedObject == "Crop")
      return (
        <>
          <div className={styled.title}>
            <Crops />
            {simu_obj.Crop.name}
          </div>
          <CropsRenderList selected={simu_obj.Crop} />
        </>
      );
    if (selectedObject == "Steering Algorithm")
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Steering Algorithm"].name}
          </div>
          <SteeringAlgorithmsRenderList
            selected={simu_obj["Steering Algorithm"]}
          />
        </>
      );
    if (
      selectedObject == "Weather Dataset" &&
      !result.simulation.universal_dataset
    )
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Weather Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["Weather Dataset"]} />
        </>
      );
    if (selectedObject == "ETP Dataset" && !result.simulation.universal_dataset)
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["ETP Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["ETP Dataset"]} />
        </>
      );
    if (selectedObject == "Climatic Dataset")
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Climatic Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["Climatic Dataset"]} />
        </>
      );
    if (selectedObject == "Pv Module Properties")
      return (
        <>
          <div className={styled.title}>
            <Icon name={ICON_NAMES_ENUM.bolt} className={styled.file__icon} />
            <FormattedMessage {...StructuresCreateMessages.PvModule} />
          </div>
          <PVModulePropertiesRenderList
            selected={simu_obj["Pv Module Properties"]}
          />
        </>
      );
    if (selectedObject == "Grassland Yield Properties")
      return (
        <>
          <div className={styled.title}>
            <Icon name={ICON_NAMES_ENUM.bolt} className={styled.file__icon} />
            <FormattedMessage {...StructuresCreateMessages.GrasslandYield} />
          </div>
          <GrasslandYieldPropertiesRenderList
            selected={simu_obj["Grassland Yield Properties"]}
          />
        </>
      );
    if (selectedObject == "General")
      return (
        <>
          <div className={styled.title}>
            <Simulation />
            General
          </div>
          <GeneralRenderList selected={simu_obj.General} />
        </>
      );
    return <></>;
  };

  function createAndDownloadCSV(data: any, fileName: any) {
    const csvRows = [];
    const headers: any[] = [];

    function flattenObject(obj: any, parentKey = '') {
      const rows = [];

      if (Array.isArray(obj)) {
        obj.forEach((item, index) => {
          const nestedRows = flattenObject(item);
          rows.push(...nestedRows);
        });
      } else if (typeof obj === 'object' && obj !== null) {
        let row = Object();
        for (const key in obj) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            const nestedRows = flattenObject(obj[key]);
            nestedRows.forEach(nRow => {
              rows.push({ ...row, ...nRow });
            });
          } else if (Array.isArray(obj[key])) {
            row = { ...row, ...flattenObject(obj[key])[0] };
          } else {
            headers.push(newKey);
            row[newKey] = obj[key];
          }
        }
        if (Object.keys(row).length) rows.push(row);
      } else {
        headers.push(parentKey);
        return [{ [parentKey]: obj }];
      }
      return rows;
    }

    const flattenedRows = flattenObject(data);
    csvRows.push([...headers].join(','));

    flattenedRows.forEach(row => {
      const csvRow: any[] = [];
      [...headers].forEach(header => {
        csvRow.push(row[header] || '');
      });
      csvRows.push(csvRow.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'data.csv';
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onDownloadConfigData = (key: keyof typeof simu_obj) => (event: any) => {
    event.stopPropagation()
    var data = null
    if (key == "General") {
      data = Object()

      data[intl.formatMessage(SimulationCreateMessages.simulationName)] = `${simu_obj.General.name}`
      data[intl.formatMessage(SimulationCreateMessages.resolution)] = `${simu_obj.General.resolution} pts/m`
      data[intl.formatMessage(SimulationDropItemMessages.timeStep)] = simu_obj.General.frequency
    }
    else if (key == "Structure") {
      let lower = 0;
      let upper = 0;
      const angle =
        simu_obj.Structure.structure_type == "fixed"
          ? simu_obj.Structure.static_angle
          : simu_obj.Structure.tracking_max_angle;
      const panel_size =
        simu_obj.Structure.structure_type == "fixed" ? simu_obj.Structure.panel_y : simu_obj.Structure.panel_x;

      const val1 =
        Math.round(
          (simu_obj.Structure.panel_height -
            (panel_size / 2) * Math.sin((angle * Math.PI) / 180)) *
          100
        ) / 100;
      const val2 =
        Math.round(
          (simu_obj.Structure.panel_height +
            (panel_size / 2) * Math.sin((angle * Math.PI) / 180)) *
          100
        ) / 100;
      if (val1 <= val2) {
        lower = val1;
        upper = val2;
      } else {
        lower = val2;
        upper = val1;
      }

      data = Object();

      data[intl.formatMessage(StructuresCreateMessages.structureName)] = simu_obj.Structure.name
      data[intl.formatMessage(StructuresRenderListMessages.lowerTableTipHeight)] = `${lower} m`
      data[intl.formatMessage(StructuresRenderListMessages.rotationAxisHeight)] = `${simu_obj.Structure.panel_height} m`
      data[intl.formatMessage(StructuresRenderListMessages.upperTableTipHeight)] = `${upper} m`
      data[intl.formatMessage(StructuresRenderListMessages.tableOpacity)] = simu_obj.Structure.panel_opacity
      data[intl.formatMessage(StructuresRenderListMessages.tableLength)] = `${simu_obj.Structure.structure_type == "fixed"
        ? simu_obj.Structure.panel_x
        : simu_obj.Structure.panel_y
        } m`
      data[intl.formatMessage(StructuresRenderListMessages.tableWidth)] = `${simu_obj.Structure.structure_type == "fixed"
        ? simu_obj.Structure.panel_y
        : simu_obj.Structure.panel_x
        } m`
      data[intl.formatMessage(StructuresRenderListMessages.gapBetweenPanels)] = `${simu_obj.Structure.structure_type == 'fixed' ? simu_obj.Structure.panels_gap_x - simu_obj.Structure.panel_x : simu_obj.Structure.panels_gap_y - simu_obj.Structure.panel_y} m`
      data[intl.formatMessage(StructuresRenderListMessages.pitchBetweenPanels)] = `${simu_obj.Structure.structure_type == 'fixed' ? simu_obj.Structure.panels_gap_y : simu_obj.Structure.panels_gap_x} m`
      data[intl.formatMessage(StructuresRenderListMessages.azimuth)] = `${simu_obj.Structure.azimuth} º`
      data[intl.formatMessage(StructuresRenderListMessages.nonFarmingBandWidth)] = `${simu_obj.Structure.no_crop_zone} m`
      data[intl.formatMessage(StructuresRenderListMessages.structureType)] = simu_obj.Structure.structure_type
      if (simu_obj.Structure.structure_type === "fixed")
        data[intl.formatMessage(StructuresRenderListMessages.panelTilt)] = simu_obj.Structure.static_angle
      else {
        data[intl.formatMessage(StructuresRenderListMessages.panelMaxTilt)] = simu_obj.Structure.tracking_max_angle
        data[intl.formatMessage(StructuresRenderListMessages.backTracking)] = simu_obj.Structure.backtracking ? "True" : "False"
      }
    } else if (key == "Soil Information") {
      data = Object()

      data[intl.formatMessage(SoilInformationsCreateMessages.soilInformationName)] = simu_obj["Soil Information"].name
      data[intl.formatMessage(SoilInformationsCreateMessages.ru_water)] = `${simu_obj["Soil Information"].ru_water} mm`
      data[intl.formatMessage(SoilInformationsCreateMessages.rfu_water)] = `${simu_obj["Soil Information"].rfu_water} mm`
      data[intl.formatMessage(SoilInformationsCreateMessages.irrigation)] = simu_obj["Soil Information"].irrigation ? 'True' : 'False'
    } else if (key == "Crop") {
      data = Object()

      data[intl.formatMessage(CropCreateMessages.cropName)] = simu_obj.Crop.name
      data["periods"] = []
      for (let index = 0; index < simu_obj.Crop.periods.length; index++) {
        const period = simu_obj.Crop.periods[index];
        console.log(period)
        data["periods"].push({})

        data["periods"][index][intl.formatMessage(AddModalPeriodCropsTableMessages.stageName)] = period.name
        data["periods"][index][intl.formatMessage(AddModalPeriodCropsTableMessages.dateStart)] = period.start_date
        data["periods"][index][intl.formatMessage(AddModalPeriodCropsTableMessages.dateEnd)] = period.end_date
        data["periods"][index][intl.formatMessage(AddModalPeriodCropsTableMessages.cropHeight)] = `${period.crop_height}`
        data["periods"][index][intl.formatMessage(AddModalPeriodCropsTableMessages.crop_coefficient)] = period.crop_coefficient
      }
    } else if (key == "Climatic Dataset") {
      data = Object()

      data[intl.formatMessage(DatasetsEditMessages.datasetName)] = simu_obj["Climatic Dataset"].name
      data[intl.formatMessage(DatasetModelMessages.datasetType)] = (simu_obj["Climatic Dataset"].dataset_type === "universal" ? "Climatic" : simu_obj["Climatic Dataset"].dataset_type)
      data[intl.formatMessage(DatasetModelMessages.samplingRate)] = simu_obj["Climatic Dataset"].frequency
      data["Origin"] = simu_obj["Climatic Dataset"].origin
      data["Year"] = simu_obj["Climatic Dataset"].year
    } else if (key == "Pv Module Properties") {
      data = Object()

      data[intl.formatMessage(StructuresCreateMessages.moduleEfficiency)] = `${simu_obj["Pv Module Properties"].module_efficiency_STC} %`
      data[intl.formatMessage(StructuresCreateMessages.temperatureCoefficientsPower)] = `${simu_obj["Pv Module Properties"].temperature_cefficients_power} %/°C`
      data[intl.formatMessage(StructuresCreateMessages.bifacialFactor)] = `${simu_obj["Pv Module Properties"].bifacial_factor} %`
    } else if (key == "Grassland Yield Properties") {
      data = Object()

      data[intl.formatMessage(StructuresRenderListMessages.raygrass)] = `${simu_obj["Grassland Yield Properties"].raygrass_frac} %`
      data[intl.formatMessage(StructuresRenderListMessages.fetuque)] = `${simu_obj["Grassland Yield Properties"].fetuque_frac} %`
      data[intl.formatMessage(StructuresRenderListMessages.dactyle)] = `${simu_obj["Grassland Yield Properties"].dactyle_frac} %`
      data[intl.formatMessage(StructuresRenderListMessages.treffle)] = `${simu_obj["Grassland Yield Properties"].treffle_frac} %`
    }


    createAndDownloadCSV(data, key + ".csv")
  }

  return (
    <div className={styled.configContainer}>
      <div className={styled.inputContainer}>
        {Object.keys(simu_obj)
          .filter((key) => simu_obj[key as keyof typeof simu_obj] != null)
          .map((key: any) => (
            <div
              className={clsx(styled.inputItem, {
                [styled.selected]: selectedObject == key,
              })}
              onClick={() => selectObject(key)}
            >
              {key}

              <Tooltip title={`Download ${key} data`} placement="right-end">
                <div onClick={onDownloadConfigData(key)}>
                  <Icon name={ICON_NAMES_ENUM.download_file} className={styled.icon} />
                </div>
              </Tooltip>
            </div>
          ))}
      </div>
      <div className={styled.displayContainer}>
        <InputDisplay />
      </div>
    </div>
  );
};

export default SimulationConfigContent;
