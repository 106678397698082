import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import AnalysisContent from "./AnalysisContent";
import AvailableWaterStockContent from "./AvailableWaterStockContent";
import WaterConsumptionContent from "./WaterConsumptionContent";
import SimulationConfigContent from "./SimulationConfigContent";
import IrradianceProductionContent from "./IrradianceProductionContent";
import WaterbalanceContent from "./WaterbalanceContent";
import ThermalStress from "./ThermalStress";
import GrasslandYieldContent from "./GrasslandYieldContent";
import moment from "moment";
import IrradianceContent from "./IrradianceContent";
import ProductionContent from "./ProductionContent";
import ETPContent from "./ETPContent";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AnimalWelfareContent from "./AnimalWelfareContent";

class SimulationResultMenuProps {
  result: any;
}

const WaterBalanceResultMenu = ({ result }: SimulationResultMenuProps) => {


  const [itemList, setItemList] = useState([
    "Simulation Parameters",
    "Analysis",
    "Irradiance",
    "Production",
    "Water Balance",
    "Thermal Stress",
  ]);
  const contents = useMemo(() => {
    return {
      ["Simulation Parameters"]: <SimulationConfigContent result={result} />,
      ["Analysis"]: <AnalysisContent result={result} />,
      ["Irradiance"]: <IrradianceContent result={result} />,
      ["Production"]: <ProductionContent result={result} />,
      ["Water Balance"]: <WaterbalanceContent result={result} />,
      ["Thermal Stress"]: <ThermalStress result={result} />,
      ["Grassland Yield"]: <GrasslandYieldContent result={result} />,
      ["Animal Welfare"]: <AnimalWelfareContent result={result} />,
    }
  }, [result]);
  const [itemChecked, setItemChecked] = useState(false)
  const [itemSelected, setItemSelected] = useState(itemList[0]);

  const [searchParams, setSearchParams] = useSearchParams();


  // console.log("menu", searchParams.get("menu"))



  useEffect(() => {
    if (
      result.simulation.grassland_primary_yield_calculation &&
      !itemList.find((title) => title == "Grassland Yield")
    )
      setItemList([...itemList, "Grassland Yield", "Animal Welfare"]);
    setItemChecked(true)
  }, [result]);

  useEffect(() => {
    if (!itemChecked) return
    const menu = searchParams.get("menu")
    console.log(menu)

    if (!itemList.find((title) => title == menu))
      setSearchParams({ menu: "Simulation Parameters" })
    else
      setItemSelected(menu!)
  }, [itemChecked])

  const onSelectItem = (item: string) => {
    setItemSelected(item);
    setSearchParams({ menu: item })

    // setTimeout(
    //   () =>
    //     window.scroll({
    //       top: 9999999,
    //       behavior: "smooth",
    //     }),
    //   50
    // );
  };

  return (
    <div className={styled.main_container}>
      <div className={styled.item_list}>
        {itemList.map((value) => (
          <button
            id={"item" + value}
            onClick={(e) => onSelectItem(value)}
            className={clsx(styled.item, {
              [styled.active]: itemSelected === value,
            })}
          >
            {value}
          </button>
        ))}
      </div>
      <div className={styled.content}>
        {contents[itemSelected as keyof typeof contents] || (
          <>Content Not Implemented</>
        )}
      </div>
    </div>
  );
};

export default WaterBalanceResultMenu;