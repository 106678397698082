import { defineMessages } from "react-intl";

export default defineMessages({
  controlZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.controlZoneSubTitle",
    defaultMessage: "Control",
  },
  underPanelsSubTitle: {
    id: "WaterBalanceResultMenuMessages.underPanelsSubTitle",
    defaultMessage: "Under Panels",
  },
  betweenPanelsZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle",
    defaultMessage: "Between Panels",
  },
  agriPVZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.agriPVZoneSubTitle",
    defaultMessage: "AgriPV",
  },
  waterConsumptionPerStage: {
    id: "WaterBalanceResultMenuMessages.waterConsumptionPerStage",
    defaultMessage: "Water Consumption per stage ({unite})",
  },
  waterDeficitPerStage: {
    id: "WaterBalanceResultMenuMessages.waterDeficitPerStage",
    defaultMessage: "Water Deficit per stage ({unite})",
  },
  irradiancePar: {
    id: "WaterBalanceResultMenuMessages.irradiancePar",
    defaultMessage: "Irradiance Daily Par (Wh/m²)",
  },
  irradianceFraction: {
    id: "WaterBalanceResultMenuMessages.irradianceFraction",
    defaultMessage: "Irradiance Fraction (%)",
  },
  production: {
    id: "WaterBalanceResultMenuMessages.production",
    defaultMessage: "Production (kWh/kWc)",
  },
  prodSunTracking: {
    id: "WaterBalanceResultMenuMessages.sunTracking",
    defaultMessage: "Sun tracking",
  },
  prodAgriPv: {
    id: "WaterBalanceResultMenuMessages.sunTracking",
    defaultMessage: "Agri PV",
  },
  tableTitle: {
    id: "WaterBalanceResultMenuMessages.tableTitle",
    defaultMessage: "Final harvestable production",
  },
  tableTitleRow: {
    id: "WaterBalanceResultMenuMessages.tableTitleRow",
    defaultMessage: "Final Harverstable Production ({unit})",
  },
  month: {
    id: "WaterBalanceResultMenuMessages.month",
    defaultMessage: "Month",
  },
  diff: {
    id: "WaterBalanceResultMenuMessages.diff",
    defaultMessage: "Diff ({unit})",
  },
  variation: {
    id: "WaterBalanceResultMenuMessages.variation",
    defaultMessage: "Variation (%)",
  },
  diff_subtitle: {
    id: "WaterBalanceResultMenuMessages.diff_subtitle",
    defaultMessage: "Control Zone / Agri PV",
  },
  tableTitleAnimalWelfare: {
    id: "WaterBalanceResultMenuMessages.tableTitleAnimalWelfare",
    defaultMessage: "Hourly Modified THI accounting for shading effects",
  },
  tableTitleAnimalWelfareSubTitle: {
    id: "WaterBalanceResultMenuMessages.tableTitleAnimalWelfareSubTitle",
    defaultMessage: "Count of Stress days",
  },
});
